@media (min-width: 768px) {
  .feed {
    max-width: 49%;
  }
}
@media (min-width: 1024px) {
  .feed {
    max-width: 32.5%;
  }
}
